@import "../../../styles/fluid.scss";

.song {
  padding: 4rem;
  @include fluid("padding-left", 20, 40);
  @include fluid("padding-right", 20, 40);

  p {
    margin-bottom: 1.5em;
    color: var(--my-color);
  }
}
