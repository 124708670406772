@import "../../../styles/fluid.scss";

/* Home */
.intro {
  background: var(--intro-bg);
  background: var(--intro-bg-moz);
  background: var(--intro-bg-wk-grad);
  background: var(--intro-bg-wk-lin);
  background: var(--intro-bg-o);
  background: var(--intro-bg-lin);
  background-repeat: repeat; 
  background-size: auto, 150px 150px;

  .intro_logo {
    padding: 6rem 4rem;
    @include fluid("padding-left", 20, 40);
    @include fluid("padding-right", 20, 40);

    h1 {
      display: inline-block;
      @include fluid("font-size", 32, 52);
      line-height: 1;
      font-weight: 750;
      text-transform: uppercase;
      color: var(--text-primary);
      vertical-align: middle;
    }

    span {
      display: inline-block;
      @include fluid("font-size", 10, 16);
      font-weight: 750;
      text-transform: uppercase;
      color: #ffffff;
      background: #69339b;
      padding: 8px;
      @include fluid("padding", 6, 8);
      border-radius: 4px;
      vertical-align: top;
      margin-left: 8px;
    }
  }

  .intro_subtitle {
    padding: 4rem;
    @include fluid("padding-left", 20, 40);
    @include fluid("padding-right", 20, 40);
    margin: 8rem 0;
    @include fluid("margin-top", 30, 80);
    @include fluid("margin-bottom", 30, 80);

    h2 {
      @include fluid("font-size", 28, 54);
      line-height: 1.5;
      color: var(--text-primary);
    }
  }

  .intro_actions {
    padding: 4rem;
    @include fluid("padding-left", 20, 40);
    @include fluid("padding-right", 20, 40);
    display: flex;
    flex-direction: column;
    align-items: stretch;

    a {
      display: inline-block;
      padding: 3rem 4rem;
      @include fluid("padding", 24, 40);
      color: var(--text-primary);
      background: var(--background-hover);
      border-radius: 0.4rem;
      outline: none;
      @include fluid("font-size", 18, 26);
      line-height: 1.5;
      text-decoration: none;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 2rem;
        @include fluid("margin-right", 24, 40);
        fill: var(--text-primary);
        @include fluid("width", 20, 28);
        @include fluid("height", 20, 28);
      }
    }

    span {
      @include fluid("font-size", 16, 22);
      color:var(--text-secondary);
      @include fluid("margin", 14, 20);
    }
  }
}

.group {
  padding: 6rem 4rem;
  @include fluid("padding-left", 20, 40);
  @include fluid("padding-right", 20, 40);

  h3 {
    @include fluid("font-size", 16, 22);
    line-height: 1.5;
    color: var(--text-secondary);
    margin-bottom: 2rem;
  }

  .group_card {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    padding: 3rem 4rem;
    @include fluid("padding", 24, 40);
    color: var(--text-primary);
    background: var(--background-hover);
    border-radius: 0.4rem;
    outline: none;
    text-decoration: none;
    opacity: 0.8;
    background: var(--group-bg);
    background: var(--group-bg-moz);
    background: var(--group-bg-wk-grad);
    background: var(--group-bg-wk-lin);
    background: var(--group-bg-o);
    background: var(--group-bg-lin);
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      opacity: 1;
    }

    svg {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 3;
      margin: auto 0;
      margin-right: 2rem;
      @include fluid("margin-right", 24, 40);
      fill: var(--text-primary);
      @include fluid("width", 20, 28);
      @include fluid("height", 20, 28);
    }

    h4 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 1;
      @include fluid("font-size", 18, 26);
      line-height: 2;
    }

    p {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      @include fluid("font-size", 16, 22);
      line-height: 1.5;
      opacity: 0.8;
      // color: var(--text-secondary);
      width: 66%;
    }
  }
}

