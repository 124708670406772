@import "../../../styles/fluid.scss";

.notfound {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  @include fluid("padding-left", 20, 40);
  @include fluid("padding-right", 20, 40);

  svg {
    fill: var(--text-secondary);
    @include fluid("width", 128, 256);
    @include fluid("height", 128, 256);
    @include fluid("margin-bottom", 20, 40);
  }

  h1 {
    @include fluid("font-size", 20, 36);
    color: var(--text-secondary);
    text-transform: uppercase;
    font-weight: 600;
    @include fluid("margin-bottom", 40, 80);
  }

  a {
    display: inline-block;
    padding: 3rem 4rem;
    @include fluid("padding", 24, 40);
    color: var(--text-primary);
    background: var(--background-hover);
    border-radius: 0.4rem;
    outline: none;
    @include fluid("font-size", 18, 26);
    text-decoration: none;
    width: calc(100% - 8rem);
    opacity: 0.8;
    text-align: center;

    &:hover {
      opacity: 1;
    }
  }
}
