/* General */
:root {
    font-size: 62.5%;
}

body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #121212;
		color: #ffffff;
		background-color: var(--background-primary);
		color: var(--text-primary);
		-webkit-tap-highlight-color: rgba(0,0,0,0);
}

main {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
}

a {
	outline: none;
}

p {
	overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}