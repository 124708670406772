/* Themes */
html,
html[data-theme="dark"] {
  --background-primary: #121212;
  --background-highlight: #1e1e1e;
  --background-hover: #1a1a1a;
  --background-divider: #282828;

  --navbar-background: #1c1c1c;
  --navbar-inactive: #8e8e8e;

  --text-primary: #ffffff;
  --text-secondary: #898989;
  
  --intro-bg: url("../media/home/pattern/dark/bg60.png");
  --intro-bg-moz: -moz-linear-gradient(top, rgba(18,18,18,0) 0%, rgba(18,18,18,1) 100%);
  --intro-bg-wk-grad: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(18,18,18,0)), color-stop(100%, rgba(18,18,18,1))), url("../media/home/pattern/dark/bg60.png");
  --intro-bg-wk-lin: -webkit-linear-gradient(top, rgba(18,18,18,0) 0%, rgba(18,18,18,1) 100%), url("../media/home/pattern/dark/bg60.png");
  --intro-bg-o: -o-linear-gradient(top, rgba(18,18,18,0) 0%, rgba(18,18,18,1) 100%), url("../media/home/pattern/dark/bg60.png");
  --intro-bg-lin: linear-gradient(to bottom, rgba(18,18,18,0) 0%, rgba(18,18,18,1) 100%), url("../media/home/pattern/dark/bg60.png");

  --group-bg: url("../media/home/group/group_background.jpg");
  --group-bg-moz: -moz-linear-gradient(left, rgba(26,26,26,1) 33%, rgba(26,26,26,0) 100%);
  --group-bg-wk-grad: -webkit-gradient(left top, right top, color-stop(33%, rgba(26,26,26,1)), color-stop(100%, rgba(26,26,26,0))), url("../media/home/group/group_background.jpg");
  --group-bg-wk-lin: -webkit-linear-gradient(right, rgba(26,26,26,1) 33%, rgba(26,26,26,0) 100%), url("../media/home/group/group_background.jpg");
  --group-bg-o: -o-linear-gradient(left, rgba(26,26,26,1) 33%, rgba(26,26,26,0) 100%), url("../media/home/group/group_background.jpg");
  --group-bg-lin: linear-gradient(to right, rgba(26,26,26,1) 33%, rgba(26,26,26,0) 100%), url("../media/home/group/group_background.jpg");
}

html[data-theme="light"] {
  --background-primary: #f8f8f8;
  --background-highlight: #efefef;
  --background-hover: #eeeeee;
  --background-divider: #dcdcdc;

  --navbar-background: #fefefe;
  --navbar-inactive: #a6a6a6;

  --text-primary: #101010;
  --text-secondary: #626262;

  --intro-bg: url("../media/home/pattern/light/bg40.png");
  --intro-bg-moz: -moz-linear-gradient(top, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%);
  --intro-bg-wk-grad: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,248,248,0)), color-stop(100%, rgba(248,248,248,1))), url("../media/home/pattern/light/bg40.png");
  --intro-bg-wk-lin: -webkit-linear-gradient(top, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%), url("../media/home/pattern/light/bg40.png");
  --intro-bg-o: -o-linear-gradient(top, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%), url("../media/home/pattern/light/bg40.png");
  --intro-bg-lin: linear-gradient(to bottom, rgba(248,248,248,0) 0%, rgba(248,248,248,1) 100%), url("../media/home/pattern/light/bg40.png");

  --group-bg: url("../media/home/group/group_background.jpg");
  --group-bg-moz: -moz-linear-gradient(left, rgba(238,238,238,1) 33%, rgba(238,238,238,0) 100%);
  --group-bg-wk-grad: -webkit-gradient(left top, right top, color-stop(33%, rgba(238,238,238,1)), color-stop(100%, rgba(238,238,238,0))), url("../media/home/group/group_background.jpg");
  --group-bg-wk-lin: -webkit-linear-gradient(right, rgba(238,238,238,1) 33%, rgba(238,238,238,0) 100%), url("../media/home/group/group_background.jpg");
  --group-bg-o: -o-linear-gradient(left, rgba(238,238,238,1) 33%, rgba(238,238,238,0) 100%), url("../media/home/group/group_background.jpg");
  --group-bg-lin: linear-gradient(to right, rgba(238,238,238,1) 33%, rgba(238,238,238,0) 100%), url("../media/home/group/group_background.jpg");
}


html.theme-transition {
  -webkit-transition: color 0.25s !important;
  transition: color 0.25s !important;
  /* Note: color transitions in Chrome seem to be ignored/delayed */
}

html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  -webkit-transition: fill 0.25s, background 0.25s !important;
  transition: fill 0.25s, background 0.25s !important;
}