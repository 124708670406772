@import "../../../styles/fluid.scss";

/* Content */
.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* margin-bottom: calc(6rem + 2.8rem); */
  @include fluid("margin-bottom", 64, 88);
  background: var(--background-primary);
  color: var(--text-primary);
}