@import "../../../styles/fluid.scss";

.actions {
  padding: 0 4rem;
  @include fluid("padding-left", 20, 40);
  @include fluid("padding-right", 20, 40);

  .actions_input {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 4rem;
    @include fluid("grid-column-gap", 20, 40);
    padding: 2rem 4rem;
    @include fluid("padding", 24, 40);
    color: var(--text-primary);
    background: var(--background-hover);
    border-radius: 0.4rem;
    outline: none;
    font-size: 2.4rem;
    @include fluid("font-size", 18, 26);
    text-decoration: none;

    input {
      color:var(--text-primary);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;	
      width: 100%;
    }

    .actions_input_icon {
      align-self: end;
      opacity: 0.6;

      svg {
        fill: var(--text-primary);
        @include fluid("width", 20, 28);
        @include fluid("height", 20, 28);
      }
    }

    .actions_input_text {
      align-self: center;
      background: transparent;
      outline: none;
      border: none;
      font-size: 2.4rem;
      @include fluid("font-size", 18, 26);
    }

    .actions_input_clear {
      align-self: center;

      svg {
        fill: var(--text-primary);
        @include fluid("width", 20, 28);
        @include fluid("height", 20, 28);
        float: right;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .actions_input_clear_inactive {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.results {
  padding: 4rem;
  @include fluid("padding-left", 20, 40);
  @include fluid("padding-right", 20, 40);

  .results_list {
    .results_list_item {
      @include fluid("font-size", 16, 24);
      line-height: 2;
      border-bottom: 1px solid var(--background-divider);
      width: 100%;
      display: flex;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: var(--background-hover);
      }

      a {
        padding: 1rem 0;
        @include fluid("padding-top", 6, 10);
        @include fluid("padding-bottom", 6, 10);
        text-decoration: none;
        color: var(--text-primary);
        width: 100%;
      }
    }
  }
}
