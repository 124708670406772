@import "../../../styles/fluid.scss";

/* Preview */
.preview {	
	padding: 4rem;
	@include fluid("padding-left", 20, 40);
	@include fluid("padding-right", 20, 40);

	.preview_title {
		@include fluid("font-size", 16, 20);
		margin-bottom: 2rem;
		opacity: 0.8;
	}

	.preview_content {
		transition: all 0.25s;
	}
}
