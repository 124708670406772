@import "../../../styles/fluid.scss";

/* Font align */
.selection {
  background: var(--background-highlight);
  padding: 4rem;
  @include fluid("padding-top", 20, 40);
  @include fluid("padding-bottom", 20, 40);

  .selection_title {
    @include fluid("font-size", 16, 20);
    text-align: center;
  }

  .options {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    width: 80%;
    margin: 6rem auto;
    @include fluid("margin-top", 10, 20);
    @include fluid("margin-bottom", 10, 20);
    @include fluid("padding-top", 10, 30);
    height: 3.4rem;

    .options_item {
      cursor: pointer;
      opacity: 0.4;
      line-height: 3.4rem;
      padding: 0 1rem;

      &:hover {
        opacity: 1;
      }

      svg {
        fill: var(--text-primary);
        @include fluid("width", 20, 28);
        @include fluid("height", 20, 28);
        vertical-align: middle;
      }
    }

    .options_item_active {
      opacity: 1;
    }
  }
}

    